@import "~bootstrap/scss/bootstrap";
@import "../abstracts/mixins";

.rounded-lg {
    border-radius: 10px !important;
    overflow: hidden;
}

.rounded-xl {
    border-radius: 200px !important;
    overflow: hidden;
}

.timeline {
    padding-top: 0px;
}

.timeline__content {
    max-width: 1000px;
    margin: auto;
}

ul.timeline__item {
    list-style-type: none;
    position: relative;
    text-align: left;
}

ul.timeline__item:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline__item > li a {
    color: #476590;
    font-size: 26px;
    font-weight: 900;
    line-height: 1.2;

    @include respond(tab-land) {
        font-size: 18px;
    }
}

ul.timeline__item > li p {
    font-size: 20px;

    @include respond(tab-land) {
        font-size: 14px;
    }
}

ul.timeline__item > li {
    margin: 20px 0 40px 0px;
    padding-left: 40px;
}

ul.timeline__item > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #476590;
    left: 15px;
    width: 30px;
    height: 30px;
    z-index: 400;
    margin-top: 3px;
}